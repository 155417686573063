import img from '../../img/work1.jpg'
const portfolio = {
    title: 'our work',
    text: 'The field of programming and design is a field of activity, so I do not stop learning and developing my skills',
    works: [
        {
            id: 1,
            title: 'Services Website ',
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            thumbnails:img,
            img:[img,img,img], 
            link: '/'

        },
        {
            id: 2,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,
            img:[img]
            , link: '/'

        },
        {
            id: 3,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        },
        {
            id: 4,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        }, {
            id: 5,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        },
        {
            id: 6,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        },
        {
            id: 7,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        },
        {
            id: 8,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        },
        {
            id: 9,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        },
        {
            id: 10,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        }, {
            id: 11,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        },
        {
            id: 12,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        },
        {
            id: 13,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        }, {
            id: 14,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        },
        {
            id: 15,
            decription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege",
            title: 'Services Website ',
            thumbnails:img,img:[img]
        },

    ]
}
export default portfolio;