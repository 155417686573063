const contact = {
   title:'contact us',
   text : "Always available, feel free to contact me, I'm happy to help. I'm waiting for you",
   subTitle:'Get in touch',
   subText:'Always available Feel free to contac',
   phone:'+213785482845',
   email:'CONTACT@contact.COM',
   location:'blida'
}


export default contact