const skils = {
    title: 'my skils',
    text: 'The field of programming and design is a field of activity, so I do not stop learning and developing my skills',
    skils: [
        { id: 1, name: 'html', progress: 80 ,color:'#31C6F5' },
        { id: 2, name: 'css', progress: 60 ,color:'#05037E' },
        { id: 3, name: 'javascript', progress: 70 ,color:'#7225D4' },
        { id: 4, name: 'php', progress: 70 ,color:'#F5313D' },
    ]
}

export default skils;