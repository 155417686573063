import s1 from '../../img/s3.png'
import s2 from '../../img/s1.png'
import s3 from '../../img/s2.png'

const service = {
    title: 'services',
    text: 'I am pleased to design a website for you and do front-end programming  and back-end while ensuring the appreciation of a fast and modern security product',
    services: [
        {
            id:1,
            img: s1,
            title: 'web design',
            description: 'Ut leo velit, tempor non lorem sit amet'
        }, {
            id:2,
            img: s2,
            title: 'web front end',
            description: 'Ut leo velit, tempor non lorem sit amet'
        }
        , {
            id:3,
            img: s3,
            title: 'web back end',
            description: 'Ut leo velit, tempor non lorem sit amet'
        }
    ]
}

export default service