import img from '../../img/about.jpg'
const about = {
    title: 'about us',
    text: 'WE ARE  WEB DESIGNER AND FULL STACK DEVELOPER',
    subTitle: 'who are we?',
    aboutText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Intege',
    subTitleTow: 'How to contact us',
    yt: '/',
    fb: '/',
    tw: '/',
    ins: '/',
    lin: '/',
    video: '8vmHzHDKQco',
    img:img
}
export default about;