import img from '../../img/user.jpg'
const testimonial = {
    title: 'testimonials',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros, sagittis dictum nunc non, tempor feugiat ante. Integer ut eros nisi. Integer. ',
    testimonials: [
        {
            id: 1,
            name: 'Hichem',
            jobe: 'ful stack developer',
            img,
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros,'
        }, {
            id: 2,
            name: 'Hichem',
            jobe: 'ful stack developer',
            img,
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros,'
        }, {
            id: 3,
            name: 'Hichem',
            jobe: 'ful stack developer',
            img,
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros,'
        }, {
            id: 4,
            name: 'Hichem',
            jobe: 'ful stack developer',
            img,
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros,'
        }, {
            id: 5,
            name: 'Hichem',
            jobe: 'ful stack developer',
            img,
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros,'
        }, {
            id: 6,
            name: 'Hichem',
            jobe: 'ful stack developer',
            img,
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur volutpat nisl sit amet volutpat. Integer tortor eros,'
        }
    ]
}

export default testimonial;