import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={608}
      height={588}
      viewBox="0 0 608 588"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M236.619 47.467C112.184 1.501 130.158 110.754 54.115 149.392c-35.257 0-68.44 145.894-47.7 239.825 20.739 93.931 89.178 83.938 205.317 85.937 116.139 1.998 16.592 85.937 143.1 107.921 126.509 21.984 82.957-33.975 182.505-81.94 99.548-47.965 73.749-214.722 43.552-243.822-95.4-91.932 8.296-209.846-58.07-245.82-116.139-45.966-214.182 62.577-286.2 35.974z"
        fill="#0D1C52"
      />
    </svg>
  )
}

export default SvgComponent